import React from "react"
import { graphql } from 'gatsby'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TakeAction from "../../components/take-action-component"
import Accordion from "../../components/accordian"
import Helmet from 'react-helmet'

// svgs
import badge from "../../images/OklahomaStandard-Badge-Blue.svg"
import heroBg from "../../images/home/hero_bg.svg"
import preservedBy from "../../images/OklahomaStandard-PreservedBy-White.svg"
// svgs

const AtWorkPage = ({data}) => (
  <Layout>
    <Helmet
      bodyAttributes={{
        class: 'has-navbar-fixed-top take-action'
      }}
    />
    <SEO title="Oklahoma Standard - Take Action At Work, At School, and In the Community" description="Live the Oklahoma Standard. Learn how to get involved in your business, school, and community by showing up to serve, rising up to honor, and stepping up to be kind." />
    <TakeAction />

    <div className="columns pv5 npt5-mobile">
      <div className="column is-4 has-background-link ph4 muscol">
        <h2 className="has-text-white is-size-xl has-text-weight-black mv1 is-uppercase titlemob">
          Serve as a team.
        </h2>
        <a className="muslink" href="https://memorialmuseum.com/">
          <img src={preservedBy} width="150" className="is-display-block mt6 is-hidden-mobile"  alt=""/>
        </a>
      </div>
      <div className="column is-offset-1 is-6 accord">
        <Accordion panels={data.wordpressPage.acf.take_action_accordion}/>
      </div>
    </div>
    <div className="columns column-pad">
      <div className="column is-12">
        <h2 className="is-size-1 has-text-weight-black is-uppercase has-text-primary has-text-centered titlemob">
          Living the<br/> Oklahoma Standard
        </h2>
      </div>
    </div>

    <div className="columns column-pad mt4 has-text-centered" >
      <div className="column is-12" dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
    </div>

    <section class="stories">
      <div class="columns column-pad pv5">
        <div class="column setoff13 is-4" >
          <h2 class="is-size-xl has-text-primary has-text-weight-black is-uppercase alignit titlemob">
            Together we will Elevate our state.
          </h2>
        </div>
        <div class="column is-4 is-offset-1 gray-badge" >
          <img src={badge} alt=""/>
        </div>
      </div>
    </section>
  </Layout>
)

export const pageQuery = graphql`
  query {
    wordpressPage(slug: {eq: "at-work"}) {
      id
      slug
      content
      acf {
        take_action_callout_page {
          __typename
          ... on WordPressAcf_Callout {
            title
            body
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        take_action_accordion {
          title
          content
        }
      }
    }
  }
`

export default AtWorkPage
